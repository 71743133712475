import React from 'react'
import styles from '../sass/components/input.module.scss'
import camelCaseClassName from '../utils/covert-sass-styles-to-camelCase'

export default ({
  type = 'text',
  size = 'normal',
  name,
  id,
  onChange,
  ...rest
}) => (
  <input
    type={type}
    name={name}
    id={id}
    {...rest}
    className={`${styles.input} ${styles[camelCaseClassName(size, 'input')]}`}
    onChange={onChange && (event => onChange(event))}
  />
)
