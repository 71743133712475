import '../sass/components/validation.scss';

import React from 'react';

import { buttonLoading } from '../sass/components/button-variants.module.scss';
import styles from '../sass/components/form.module.scss';

export const FormItems = ({ children }) => (
  <dl className={styles.form__items}>{children}</dl>
)

export const FormHeader = ({ children }) => (
  <header className={styles.form__header}>{children}</header>
)

export const FormForgotten = ({ children }) => (
  <p className={styles.form__forgotten}>{children}</p>
)

export const FormError = ({ children }) => (
  <p className={styles.form__error}>{children}</p>
)

export const FormSubmit = ({ to, children, ...rest }) => (
  <button
    type="submit"
    className={`${styles.form__submit} ${rest.disabled ? buttonLoading : ''}`}
    {...rest}
  >
    {children}
  </button>
)

export class Form extends React.Component {
  state = {
    isValidated: false,
  }

  submitHandler = event => {
    event.preventDefault()
    event.target.classList.add('was-validated')

    if (this.formEl.checkValidity() !== false) {
      this.props.onSubmit(event)
    }

    this.setState({ isValidated: true })
  }

  render() {
    return (
      <form
        ref={form => (this.formEl = form)}
        {...this.props}
        onSubmit={this.submitHandler}
        className={styles.form}
        noValidate
      >
        {this.props.children}
      </form>
    )
  }
}
