export default (query, resolve) =>
  fetch('https://graphql.datocms.com/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer 34791ab0b548177eb4d4df8bac9eda`,
    },
    body: JSON.stringify({
      query: `{${query}}`,
    }),
  })
    .then(res => res.json())
    .then(({ data }) => (resolve ? resolve(data) : data))
    .catch(error => (resolve ? resolve(error) : error))
